import React from 'react';
import './Separator.css';

const Separator = ({ children, as: Tag = 'h2', color = 'inherit' }) => {

    return (
        <div className="Separator">
            <Tag className="Separator__heading" style={{ color }}>{children}</Tag>
        </div>
    );
};

export default Separator;
