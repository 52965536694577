import './Clientes.css'
import logoNorden from '../../assets/images/logo-norden.svg'
import logoSanasalud from '../../assets/images/logo-sanasalud.svg'
import logoAlemana from '../../assets/images/logo-alemana.svg'
import logoFALP from '../../assets/images/logo-falp.svg'
import logoMerced from '../../assets/images/logo-merced.png'
import logoProMagdalena from '../../assets/images/logo-pro-magdalena.svg'
import logoBlueCare from '../../assets/images/logo-blue-care.svg'
import logoAlmaMater from '../../assets/images/logo-alma-mater.svg'
import logo from '../../assets/images/logo-white.svg'
import Separator from '../Separator'
import Heading from '../Heading'

const Clientes = () => {
  return (
    <section className="Clientes">
      <Heading as="h2" size="small" color="white">
        <img src={logo} className="Clientes__logo_cero" alt="CERO" /> es usado por equipos de entre 10 y +10.000 profesionales
      </Heading>
      <div className='Clientes__container'>
        <div className='Clientes__group'>
          <Separator as="h4" color="white">Chile</Separator>
          <div className='Clientes__list'>
            <img
              className="Clientes__logo"
              src={logoNorden}
              alt="Logo Norden"
            />
            <img
              className="Clientes__logo"
              src={logoSanasalud}
              alt="Logo Sanasalud"
            />
            <img
              className="Clientes__logo"
              src={logoAlemana}
              alt="Logo Alemana"
            />
            <img
              className="Clientes__logo"
              src={logoFALP}
              alt="Logo FALP"
            />
            <a
              className="Clientes__logo--merced"
              href="https://clinicamerced.cl/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="Clientes__logo"
                src={logoMerced}
                alt="Logo Clínica Merced"
              />
            </a>

          </div>
        </div>
        <div className='Clientes__group'>
          <Separator as="h4" color="white">Colombia</Separator>
          <div className='Clientes__list'>
          <img
              className="Clientes__logo"
              src={logoBlueCare}
              alt="Blue Care"
            />
            <img
              className="Clientes__logo"
              src={logoProMagdalena}
              alt="Fundación Pro Magdalena"
            />
            <img
              className="Clientes__logo"
              src={logoAlmaMater}
              alt="Alma Mater"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clientes
