import './Testimonios.css'
import fotoDiegoMoreira from '../../assets/images/testimonios/Diego_Moreira_SS.jpeg'
import fotoMaxHasenohr from '../../assets/images/testimonios/Max_Hasenohr_NO.png'
import fotoChristianOyaneder from '../../assets/images/testimonios/Christian_Oyaneder_MERCED.jpg'
import { InlineIcon } from '@iconify/react'

const testimonios = [
  {
    foto: fotoDiegoMoreira,
    nombre: 'Diego Moreira',
    cargo: 'Gerente General',
    institucion: 'Sanasalud',
    link: 'https://sanasalud.cl/',
    cita: 'Gracias a Cero, hoy tenemos un canal de comunicación efectivo con más del 90% de nuestros pacientes y por una fracción del costo de un call center.',
  },
  {
    foto: fotoMaxHasenohr,
    nombre: 'Max Hasenohr',
    cargo: 'Gerente Comercial y Marketing',
    institucion: 'Norden Dental Care',
    link: 'https://clinicasnorden.cl/',
    cita: 'Con Cero logramos automatizar el proceso de confirmación de citas de nuestras clínicas y reducir las inasistencias de pacientes, liberando a nuestros ejecutivos de atención al cliente para casos más complejos.',
  },
  {
    foto: fotoChristianOyaneder,
    nombre: 'Christian Oyaneder Ojeda',
    cargo: 'Director',
    institucion: 'Clínica Merced',
    link: 'https://clinicamerced.cl/',
    cita: 'Si hay algo que caracteriza a Cero es el acompañamiento que entregan. Se preocupan de cada detalle para que su solución de contacto a pacientes se adapte a las necesidades de nuestro centro.',
  },
]

const Testimonios = () => {
  return (
    <div className="Testimonios">
      <div className="Testimonios__textos">
        <h3 className="Testimonios__subtitulo">
          Lo que dicen quienes han confiado en nosotros
        </h3>
        {testimonios.map((testimonio) => (
          <div
            key={`contenedor-testimonio-${testimonio.nombre}`}
            className="Testimonios__contenedor_cita"
          >
            <InlineIcon
              className="Testimonios__icono_cita"
              icon="mdi:format-quote-close"
            />
            <p className="Testimonios__cita">{testimonio.cita}</p>
            <img
              className="Testimonios__avatar"
              src={testimonio.foto}
              alt={`Foto ${testimonio.nombre}`}
            />
            <p className="Testimonios__nombre">{testimonio.nombre}</p>
            <p className="Testimonios__cargo">
              {testimonio.cargo},{' '}
              <a
                href={testimonio.link}
                target="_blank"
                rel="noreferrer noopener"
                className="Testimonios__link"
              >
                {testimonio.institucion}
              </a>
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Testimonios
