import React, { useState, useEffect } from 'react';
import './Typewriter.css';

const Typewriter = ({ 
  words, 
  typingSpeed = 100, 
  deletingSpeed = 50, 
  pauseTime = 3000,
  displayType = 'inline-block'
}) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    const currentWord = words[currentWordIndex];
    const handleTyping = () => {
      if (isDeleting) {
        if (displayedText.length > 0) {
          setDisplayedText((prev) => prev.slice(0, -1));
          setIsBlinking(false);
        } else {
          setIsDeleting(false);
          setCurrentWordIndex((prev) => (prev + 1) % words.length);
        }
      } else {
        if (displayedText.length < currentWord.length) {
          setDisplayedText((prev) => currentWord.slice(0, prev.length + 1));
          setIsBlinking(false);
        } else {
          setIsBlinking(true);
          setTimeout(() => {
            setIsDeleting(true);
            setIsBlinking(false);
          }, pauseTime);
        }
      }
    };

    const typingTimeout = setTimeout(handleTyping, isDeleting ? deletingSpeed : typingSpeed);

    return () => clearTimeout(typingTimeout);
  }, [displayedText, isDeleting, words, currentWordIndex, typingSpeed, deletingSpeed, pauseTime]);

  return (
    <div style={{ display: displayType }}>
      <span>{displayedText}</span>
      <span className={`cursor ${isBlinking ? 'blink' : ''}`}>|</span>
    </div>
  );
};

export default Typewriter;