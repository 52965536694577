import React from 'react';
import './Heading.css';

const Heading = ({ as: Tag = 'h1', size = 'medium', color = 'inherit', children }) => {
    return (
        <div className={`Heading Heading--${size}`} style={{ color }}>
            <Tag className="Heading__title">{children}</Tag>
        </div>
    );
};

export default Heading;
