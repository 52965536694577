import { useDispatch, useSelector } from 'react-redux'
import { esconderCasosDeUso } from '../../../redux/ducks/modals'
import Casos from '../../Casos'
import './CasosDeUso.css'
import { Icon } from '@iconify/react'

const CasosDeUso = () => {
  const { casosDeUsoVisibles } = useSelector((state) => state.modals)
  const dispatch = useDispatch()

  return (
    <article className='ModalWrapper'>
      <div
        className={`Modal__overlay ${casosDeUsoVisibles ? 'Modal__overlay--activo' : ''}`}
        onClick={() => dispatch(esconderCasosDeUso())}
      >
        <div
          className={`Modal ${casosDeUsoVisibles ? 'Modal--visibles' : ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={() => dispatch(esconderCasosDeUso())}
            className="Modal__boton_cerrar"
            title="Cerrar ventana"
          >
            <Icon icon="mdi:close" />
          </button>
          <Casos />
        </div>
      </div>
    </article>
  )
}

export default CasosDeUso