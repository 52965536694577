import { Route, Routes } from 'react-router-dom'
import Apoyo from '../Apoyo'
import BarraSuperior from '../BarraSuperior'
import Capacidad from '../Capacidad'
import Casos from '../Casos'
import Clientes from '../Clientes'
import Contacto from '../Contacto'
import Hero from '../Hero'
import Integraciones from '../Integraciones'
import Tecnologia from '../Tecnologia'
import Testimonios from '../Testimonios'
import './App.css'

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/agenda-tu-demo" element={
          <>
            <BarraSuperior />
            <Contacto />
          </>
        } />
        <Route path="/contacto" element={
          <>
            <BarraSuperior />
            <Contacto />
          </>
        } />
        <Route path="/casos-de-uso" element={
          <>
            <BarraSuperior />
            <Casos />
          </>
        } />
        <Route path="/" element={
          <>
            <BarraSuperior />
            <Hero />
            <Clientes />
            <Capacidad />
            <Integraciones />
            <Tecnologia />
            <Testimonios />
            <Apoyo />
          </>
        }
        />
      </Routes>
    </div>
  )
}

export default App
