import { useState } from 'react'
import SlideCelularGenerica2022 from '../Capacidad/SlideCelularGenerica2022/SlideCelularGenerica2022'
import classNames from 'classnames'
import './Casos.css'
import casos from '../Capacidad/CasosDeUso/casos'

const Casos = () => {
  const [indiceCasoSeleccionado, setIndiceCasoSeleccionado] = useState(0)

  const handleCasoSeleccionado = (i) => {
    setIndiceCasoSeleccionado(i)
  }

  return (
    <div
      className="CasosDeUso"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="CasosDeUso__contenedor_botones">
        {casos.map((caso, i) => (
          <button
            onClick={() => handleCasoSeleccionado(i)}
            key={`boton-caso-${i}`}
            className={classNames({
              CasosDeUso__boton: true,
              'CasosDeUso__boton--activo': i === indiceCasoSeleccionado,
            })}
          >
            {caso.titulo}
            {i === indiceCasoSeleccionado && (
              <div className="CasosDeUso__marca_seleccion" />
            )}
          </button>
        ))}
      </div>
      <div className="CasosDeUso__contenedor_caso">
        <SlideCelularGenerica2022
          titulo={casos[indiceCasoSeleccionado].titulo}
          mensajesEntrantes={casos[indiceCasoSeleccionado].mensajesEntrantes}
          mensajesSalientes={casos[indiceCasoSeleccionado].mensajesSalientes}
        />
      </div>
    </div>
  )
}

export default Casos